<template>
	<Dialog :dialog="detailDialog" :dialog-width="640" @close="$emit('close', true)">
		<template v-slot:title>
			<div class="d-flex w-100">
				<div>
					Leave
					<span class="ml-2 orange--text">#{{ leaveData.barcode }}</span>
				</div>
				<v-spacer></v-spacer>
				<div>
					<!-- <StatusInput
						v-if="leaveData && leaveData.status && leaveData.status != 'Rejected'"
						v-model="leaveData.status"
						:items="mod_statusList"
						@change="(value) => updateStatus(value, 'status')"
					/> -->

					<v-menu
						v-if="
							leaveData &&
							leaveData.status &&
							leaveData.status != 'Rejected' &&
							getPermission('leave:approve')
						"
						offset-y
						min-width="100px"
						style="padding: 0px 4px"
						rounded
						bottom
					>
						<template v-slot:activator="{ on, attrs }">
							<div v-bind="attrs" v-on="on">
								<v-chip
									class="ml-1 mb-1 pl-2 pr-1 text-capitalize"
									:color="`${
										leaveData.status == 'Pending'
											? 'orange'
											: leaveData.status == 'Approved'
											? 'green'
											: 'red'
									} white--text`"
									small
								>
									{{ leaveData.status }}
									<v-icon style="font-size: 22px" small>mdi-menu-down</v-icon>
								</v-chip>
							</div>
						</template>

						<v-list elevation v-if="leaveData && leaveData.status && leaveData.status != 'Rejected'">
							<v-list-item
								class="list-item cursor-pointer p-1"
								v-for="(item, index) in mod_statusList"
								:key="index"
								@click="updateStatus(item)"
							>
								<!-- @click="updateStatus(leaveData.id, item)" -->
								<v-list-item-title class="pl-4">{{ item.text }}</v-list-item-title>
							</v-list-item>
						</v-list>
					</v-menu>

					<v-chip v-if="leaveData.status == 'Rejected'" color="red">
						<span class="white--text text-capitalize">{{ leaveData.status }}</span>
					</v-chip>
				</div>
			</div>
		</template>
		<template v-slot:body>
			<v-row class="delete-dialog">
				<v-col md="12" class="py-0 my-auto">
					<template v-if="leaveData && leaveData.staff">
						<div class="mb-3 d-flex blue lighten-5 pa-2">
							<div class="profile-img">
								<v-avatar size="100" class="mr-4">
									<img v-if="leaveData.staff.profile_img" :src="leaveData.staff.profile_img" />
									<img v-else src="https://ams-dev.businessthrust.com//media/misc/no_photo_found.png" />
								</v-avatar>
							</div>
							<div class="profile-desc">
								<div class="mb-2">
									<span class="fw-600">User # : </span>
									<span class="fw-500" v-if="leaveData.staff.barcode"
										><v-chip color="cyan" label outlined x-small class="text-capitalize">
											{{ leaveData.staff.barcode }}
										</v-chip>
									</span>
								</div>
								<div class="mb-2">
									<span class="fw-600">Name : </span>
									<span class="fw-500" v-if="leaveData.staff.display_name">{{
										leaveData.staff.display_name
									}}</span>
								</div>

								<div class="mb-2" v-if="probisionTime >= 0">
									<span class="fw-600">Probation Period : </span>
									<span class="fw-500" v-if="leaveData.staff.joining_date && leaveData.staff.provision_date"
										>{{ formatDate(leaveData.staff.joining_date) }} <b>To</b>
										{{ formatDate(leaveData.staff.provision_date) }}</span
									>
									<span class="fw-600 ml-2">
										<v-chip
											v-if="probisionTime >= 0"
											color="red"
											class="white--text"
											outlined
											style="letter-spacing: 0.8px"
											small
										>
											Probation {{ getProvision(leaveData.staff.provision_date) }}
										</v-chip>
									</span>
								</div>

								<div class="mb-2">
									<span class="fw-600">Designation : </span>
									<v-chip
										color="primary"
										label
										outlined
										x-small
										class="ml-2 text-capitalize"
										v-if="leaveData.staff.profile"
									>
										<template v-if="leaveData.staff.profile == 'email-marketer'">Email Marketer</template>
										<template v-else>{{ leaveData.staff.profile }}</template>
									</v-chip>
								</div>
							</div>
						</div>
					</template>
					<div>
						<table cellspacing="5">
							<tr>
								<td class="pa-2">Type</td>
								<td width="20" class="text-center pa-2">:</td>
								<td class="pa-2">
									<v-chip label small outlined color="blue darken-4">
										{{ leaveData.category }}
									</v-chip>
								</td>
							</tr>
							<tr>
								<td class="pa-2">Start Date</td>
								<td width="20" class="text-center pa-2">:</td>
								<th class="pa-2">
									{{ formatDate(leaveData.start_date) }}
									<template v-if="leaveData && leaveData.duration">
										<v-chip
											class="mr-1"
											label
											small
											outlined
											color="blue darken-4"
											v-if="leaveData && leaveData.duration_type == 2"
										>
											Second Half
										</v-chip>
									</template>
								</th>
							</tr>
							<tr>
								<td class="pa-2">End Date</td>
								<td width="20" class="text-center pa-2">:</td>
								<th class="pa-2">
									{{ formatDate(leaveData.end_date) }}
									<template v-if="leaveData && leaveData.duration">
										<v-chip
											class="mr-1"
											label
											outlined
											color="blue darken-4"
											small
											v-if="leaveData && leaveData.duration_type == 1"
										>
											First Half
										</v-chip>
									</template>
								</th>
							</tr>
							<tr v-if="leaveData.status == 'Rejected'">
								<td class="pa-2">Rejected Date</td>
								<td width="20" class="text-center pa-2">:</td>
								<th class="pa-2">
									{{ formatDate(leaveData.rejected_at) }}
								</th>
							</tr>
							<tr>
								<td class="pa-2">Total Days</td>
								<td width="20" class="text-center pa-2">:</td>
								<th class="pa-2">
									<v-chip label small outlined color="blue darken-4">
										{{ leaveData.total_leave_days }}
									</v-chip>
								</th>
							</tr>
						</table>
						<div class="border mt-4">
							<div class="pa-2 border-bottom fw-600">Reason for Leave</div>
							<div class="pa-2" style="white-space: wrap; text-align: justify">
								<template v-if="leaveData.reason">
									{{ leaveData.reason }}
								</template>
								<template v-else>
									<em class="text-muted">no reason</em>
								</template>
							</div>
						</div>
						<div class="border mt-4">
							<div class="pa-2 border-bottom fw-600">Attachments</div>
							<div class="pa-2">
								<template v-if="leaveData && leaveData.attachments && leaveData.attachments.length">
									<div
										class="my-1 border-bottom"
										v-for="(attachmentInfo, index) in leaveData.attachments"
										:key="index"
									>
										<div class="d-flex align-items-center justify-content-between">
											<div class="d-flex align-items-center">
												<div>{{ index + 1 }}</div>
												<div class="ml-2 fw-600">
													<div>
														{{ attachmentInfo.name }}
													</div>
												</div>
											</div>
											<div>
												<v-btn
													icon
													depressed
													color="blue darken-4"
													class="mr-1"
													v-on:click.prevent.stop="downloadAttachment(attachmentInfo.url)"
													><v-icon small>mdi-download</v-icon></v-btn
												>
											</div>
										</div>
									</div>
								</template>
								<template v-else>
									<em class="text-muted"> no attachment</em>
								</template>
							</div>
						</div>
						<div class="border mt-4" v-if="leaveData.reject_remark">
							<div class="pa-2 border-bottom fw-600">Reject Reason</div>
							<div class="pa-2" style="white-space: wrap; text-align: justify">
								<template v-if="leaveData.reject_remark">
									{{ leaveData.reject_remark }}
								</template>
								<template v-else>
									<em class="text-muted">no reason</em>
								</template>
							</div>
						</div>
					</div>
				</v-col>
			</v-row>
		</template>
		<template v-slot:action>
			<v-btn depressed tile v-on:click="closeDialog()"> Close </v-btn>
		</template>
	</Dialog>
</template>
<script>
import MainMixin from "@/core/mixins/main.mixin.js";
import Dialog from "@/view/components/Dialog";
// import StatusInput from "@/view/components/StatusInput.vue";
import {
	GET,
	// PATCH
} from "@/core/services/store/request.module";
// import { LeaveEventBus } from "@/core/lib/leave.form.lib";
import MomentJS from "moment-timezone";
import { cloneDeep } from "lodash";

export default {
	mixins: [MainMixin],
	name: "Leave-Detail-Dialog",
	props: {
		id: {
			type: [String, Number],
			default: null,
		},
		dialog: {
			type: Boolean,
			default: false,
		},
		statusList: {
			type: Array,
			default: () => {
				return [];
			},
		},
		// statusDailog: {
		// 	type: Boolean,
		// },
		// statusData: {
		// 	type: Number,
		// },
	},
	watch: {
		dialog(param) {
			this.detailDialog = param;
			if (param) {
				this.getLeaveDetail();
			}
		},
		"leaveData.status": {
			deep: true,
			handler(param) {
				let _status = cloneDeep(this.statusList);
				if (param == "Pending") {
					this.mod_statusList = _status.filter((v) => v.text != param);
				}
				if (param == "Rejected") {
					this.mod_statusList = [];
				}
				if (param == "Approved") {
					this.mod_statusList = _status.filter((v) => v.text != param && v.text != "Pending");
				}
			},
		},
	},
	data() {
		return {
			detailDialog: false,
			pageLoading: false,
			probisionTime: 0,
			leaveData: {},
			mod_statusList: [],
		};
	},
	methods: {
		closeDialog() {
			this.$emit("close", true);
		},
		getLeaveDetail() {
			const _this = this;
			_this.pageLoading = true;
			_this.$store
				.dispatch(GET, {
					url: "single-leave/" + _this.id,
				})
				.then((data) => {
					_this.leaveData = data;
					_this.getProvision(data.staff.provision_date);
				})
				.catch((error) => {
					_this.logError(error);
				})
				.finally(() => {
					_this.pageLoading = false;
				});
		},
		getProvision(proDate) {
			let a = MomentJS();
			let b = MomentJS(proDate);
			this.probisionTime = b.diff(a, "days");
		},
		updateStatus(status) {
			let _status = status.text == "Approved" ? 2 : status.text == "Rejected" ? 3 : 0;
			this.$emit("leave-details", { barcode: this.leaveData.barcode, leaveId: this.id });
			this.$emit("status-data", _status);
			this.$emit("status-dialog", true);

			// let action = "";
			// switch (status) {
			// 	case "Pending":
			// 		action = "mark_as_pending";
			// 		break;
			// 	case "Approved":
			// 		action = "mark_as_approved";
			// 		break;
			// 	case "Rejected":
			// 		action = "mark_as_rejected";
			// 		break;
			// }
			// const payload = { action , selected: [this.id] };
			// this.$store
			// 	.dispatch(PATCH, {
			// 		url: `leave/${this.leaveId}/${this.statusdata}`,
			// 		data: {
			// 			reject_remark: this.statusdata == 3 ? this.reject_remark : null,
			// 		},
			// 	})
			// 	.then(() => {
			// 		this.getLeaveDetail();
			// 		this.$emit("refresh", this.id);
			// 		LeaveEventBus.$emit("refresh", true);
			// 	})
			// 	.catch((error) => {
			// 		console.log({
			// 			error,
			// 		});
			// 	});
		},
		downloadAttachment(url) {
			window.open(url, "_blank");
		},
	},
	components: {
		Dialog,
		// StatusInput,
	},
};
</script>
