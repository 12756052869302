<template>
	<div class="leave-kanban">
		<div class="kanban-view-list">
			<v-flex
				class="kanban-innerlist"
				v-for="(row, index) in leaveData"
				:key="'leave_kanban_' + index"
			>
				<div :class="`${row.status.status_color} lighten-4 list-items`">
					<!-- <div class="d-flex">
						<div class="fw-600 title-text">{{ row.status.text }}</div>
						<v-spacer></v-spacer>
					</div>
					<div class="mt-1" v-if="row.status && row.status.status_color">
						<v-chip class="mb-1 mr-1" label :color="`${row.status.status_color} white--text`" x-small>
							{{ row.list.length }} leave
						</v-chip>
					</div> -->
					<div class="d-flex align-center">
						<div class="fw-600 title-text text-h6">{{ row.status.text }}</div>
						<v-tooltip top>
							<template #activator="{ on, attrs }">
								<v-chip
									class="ml-3"
									v-on="on"
									v-bind="attrs"
									:color="`${row.status.status_color} white--text`"
								>
									<span class="text-h6">
										{{ row.total_rows }}
									</span>
								</v-chip>
							</template>
							<span>Total Records</span>
						</v-tooltip>
						<v-spacer></v-spacer>
						<!-- <v-checkbox hide-details class="ma-0 pa-0 size-16"> </v-checkbox> -->
					</div>
				</div>

				<Container
					style="max-height: calc(100vh - 300px)"
					group-name="leadkanbanlist"
					@drop="onDrop(index, row.list, $event, row.status.value)"
					:get-child-payload="(e) => getChildPayload(row.list, index, e)"
					:drop-placeholder="dropPlaceholderOptions"
					class="overflow-list-item"
					:id="!row.list.length ? 'blank-list' : ''"
					:lock-axis="index == 2 ? 'y' : undefined /* To Prevent dragging Rejected Leaves in x axis */"
				>
					<Draggable v-model="row.status.value" v-for="(data, indx) in row.list" :key="indx" class="">
						<div
							:class="`${row.status.status_color} lighten-5 list-items`"
							v-on:click.prevent.stop="openDialog(data.id)"
						>
							<!-- <div class="ellipsis pr-6">
								<div class="ellipsis pr-6">
									<span class="fw-600 pr-2">Leave Number : </span>
									<span class="fw-500" v-if="data && data.barcode">{{ data.barcode }}</span>
								</div>
							</div> -->

							<div class="d-flex">
								<div class="ellipsis fw-500 mr-2 w-80">
									<div class="ellipsis pr-6">
										<span class="fw-600 pr-2">Staff : </span>
										<span class="fw-500" v-if="data && data.display_name">{{ data.display_name }}</span>
									</div>
								</div>
								<div class="ellipsis fw-500 text-end w-20" v-if="row.status.value == 1">
									<!-- <v-icon
										v-if="getPermission('leave:update')"
										style="font-size: 18px"
										class="mr-2"
										color="cyan"
										small
										v-on:click.prevent.stop="UpdateLeave(data.id)"
										>mdi-pencil</v-icon
									> -->
								</div>
							</div>
							<!-- <div class="ellipsis pr-6">
								<div class="ellipsis pr-6">
									<span class="fw-600 pr-2">Probation Period : </span>
									<span class="fw-500" v-if="data && data.joining_date && data.provision_date"
										>{{ formatDate(data.joining_date) }} <b>To</b> {{ formatDate(data.provision_date) }}</span
									>
								</div>
							</div> -->
							<!-- <div class="d-flex">
								<div class="ellipsis fw-500 w-80">
									<span class="fw-600 pr-2">Leave # : </span>
									<template v-if="data && data.barcode">
										<span class="fw-500">{{ data.barcode }}</span>
									</template>
									<template v-else>no barcode</template>
								</div>
								
							</div> -->
							<div class="d-flex">
								<div class="ellipsis pr-6">
									<span class="fw-600 pr-2">Type : </span>
									<v-chip v-if="data && data.category" label color="blue" outlined small>
										{{ data.category }}
									</v-chip>
								</div>
								<v-spacer></v-spacer>
								<div class="text-end">
									<template v-if="getProvision(data.provision_date) >= 0">
										<span class="fw-500">
											<v-chip
												v-if="getProvision(data.provision_date) >= 0"
												color="red"
												class="white--text"
												outlined
												style="letter-spacing: 0.8px"
												small
											>
												Probation
											</v-chip>
										</span>
									</template>
								</div>
							</div>
							<!-- <div class="ellipsis pr-6">
								<div class="ellipsis pr-6">
									<span class="fw-600 pr-2">Application Date : </span>
									<span class="fw-500" v-if="data && data.application_date"
										>{{ formatDate(data.application_date) }}
									</span>
								</div>
							</div> -->
							<div class="ellipsis pr-6">
								<div class="ellipsis pr-6">
									<span class="fw-600 pr-2">Leave : </span>
									<span class="fw-500 mx-1" v-if="data && data.start_date">
										<span class="text-muted"> From : </span>
										{{ formatDate(data.start_date) }}
									</span>
									<span class="fw-500 px-1" v-if="data && data.end_date">
										<span class="text-muted">To : </span>
										{{ formatDate(data.end_date) }}
									</span>
								</div>
							</div>
							<template v-if="data && data.duration">
								<span class="fw-600 pr-2">Half Day : </span>
								<v-chip
									class="mb-1 mr-1"
									label
									:color="`${row.status.status_color} white--text`"
									small
									v-if="data && data.duration_type == 1"
								>
									<template v-if="data.day_type == 1">
										{{ formatDate(data.start_date) }}
									</template>
									<template v-else>
										{{ formatDate(data.end_date) }}
									</template>
									(First Half)
								</v-chip>
								<v-chip
									class="mb-1 mr-1"
									label
									:color="`${row.status.status_color} white--text`"
									small
									v-else
								>
									<template v-if="data.day_type == 1">
										{{ formatDate(data.start_date) }}
									</template>
									<template v-else> {{ formatDate(data.end_date) }} </template>
									(Second Half)
								</v-chip>
							</template>
							<div class="ellipsis pr-6">
								<div class="ellipsis pr-6">
									<span class="fw-600 pr-2">Days : </span>
									<span class="fw-500" v-if="data && data.total_leave_days">
										<v-chip
											class="mb-1 mr-1 px-2"
											outlined
											label
											:color="`${row.status.status_color} white--text`"
											small
										>
											{{ data.total_leave_days }}
										</v-chip>
									</span>
									<span v-if="getProvision(data.provision_date) >= 0"> (Unpaid leave)</span>
								</div>
							</div>
							<div class="ellipsis pr-6" v-if="false">
								<div class="ellipsis pr-6">
									<span class="fw-600 pr-2">Reason : </span>
									<span class="fw-500" v-if="data && data.reason">{{ data.reason }}</span>
								</div>
							</div>
						</div>
					</Draggable>
					<div class="text-center mb-3">
						<v-btn
							class="white--text"
							@click="loadMore(row)"
							v-if="row.total_rows > row.list.length"
							depressed
							:color="row.status.status_color"
						>
							<v-icon left color="white">mdi-reload</v-icon> Load more
						</v-btn>
					</div>
				</Container>
				<DeleteTemplate
					type="leave"
					:deleteUrl="delURL"
					:deleteText="delTEXT"
					:deleteDialog="confirm_dialog"
					v-on:close="confirm_dialog = false"
					v-on:success="refresh"
				></DeleteTemplate>

				<!-- <Dialog
					:dialog="statusDialog"
					:dialog-width="statusdata == 2 ? 440 : 600"
					@close="statusDialog = false"
				>
					<template v-slot:title> Confirmed</template>
					<template v-slot:body>
						<v-form v-model="formValid">
							<v-row class="delete-dialog">
								<v-col md="12" class="py-0 my-auto py-2">
									<p class="btx-p m-0" v-if="statusdata == 2">
										<span class="green--text lighten-1 font-level-1-bold">
											<span style="font-size: 19px !important"> Are you sure, </span>
											<br /><br />
											You want to Approve this Leave
											<v-chip outlined label color="green darken-1"
												><span class="fw-600">{{ leaveBarcode }}</span> </v-chip
											>&nbsp;?
										</span>
									</p>
									<div v-else>
										<span class="red--text lighten-1 font-level-1-bold">
											<span style="font-size: 19px !important"> Are you sure, </span>
											<br /><br />
											You want to Reject this Leave
											<v-chip outlined label color="red darken-1">
												<span class="fw-600">{{ leaveBarcode }}</span> </v-chip
											>&nbsp; ?
										</span>

										<div class="mt-3">
											<label class="input-label required mb-2">Reject Remark</label>
											<TextInput
												v-model="reject_remark"
												:rules="[vrules.required(reject_remark, 'Reject Remark')]"
												hide-details
												outlined
												class="mt-0"
												:class="{
													required: !reject_remark,
												}"
											></TextInput>
										</div>
									</div>
								</v-col>
							</v-row>
						</v-form>
					</template>
					<template v-slot:action>
						<v-btn
							class="white--text"
							depressed
							color="blue darken-4"
							:disabled="!formValid"
							tile
							v-on:click="onSubmit()"
						>
							Yes
						</v-btn>
						<v-btn depressed tile v-on:click="closeMainDialog()"> Close </v-btn>
					</template>
				</Dialog> -->
			</v-flex>
		</div>
	</div>
</template>
<script>
import { Container, Draggable } from "vue-smooth-dnd";
import { QUERY, PATCH } from "@/core/services/store/request.module";
import { /* filter, */ cloneDeep } from "lodash";
import { LeaveEventBus } from "@/core/lib/leave.form.lib";
import DeleteTemplate from "@/view/components/DeleteTemplate";
import { getCurrentUser } from "@/core/services/jwt.service";
import MomentJS from "moment-timezone";
import objectPath from "object-path";
/* import objectPath from "object-path"; */
// import Dialog from "@/view/components/Dialog";
// import TextInput from "@/view/components/TextInput";
export default {
	name: "LeaveKanban",
	props: {
		statusDailog: {
			type: Boolean,
		},
		statusData: {
			type: Number,
		},
	},
	data() {
		return {
			dropPlaceholderOptions: {
				className: "lead-drop-preview",
				animationDuration: "50",
				showOnTop: true,
			},
			leaveData: [] /* {
				pending: null,
				approved: null,
				rejected: null
			} */,
			statusvalue: 0,
			formValid: true,
			leaveId: 0,
			probisionTime: 0,
			confirm_dialog: false,
			confirm: false,
			delURL: null,
			dropObjectData: {},
			statusDialog: false,
			status_remark: {
				drop_status: "Cancelled",
				dropData: null,
				drop_value: 0,
			},
			delTEXT: null,
			reject_remark: null,
		};
	},
	watch: {
		$route: {
			deep: true,
			handler() {
				const { query } = this.$route;
				console.log({ query });
				this.getLeaveKanban(query);
			},
		},
	},
	methods: {
		loadMore(row) {
			const { status, list, /* total_page, */ current_page, total_rows } = row;
			/* console.log({ status, total_page }); */
			if (total_rows > list.length) {
				let page = current_page + 1;
				const { query } = this.$route;
				let index = objectPath.get(status, "text")
					? objectPath.get(status, "text") == "Pending"
						? 0
						: objectPath.get(status, "text") == "Approved"
						? 1
						: 2
					: null;
				if (objectPath.get(status, "value")) {
					const _query = { ...query, page, status: status.value };
					this.getLeaveKanban(_query, index);
				}
			}
		},
		closeDialog() {
			this.statusDialog = false;
		},
		openStatusDialog() {
			this.statusDialog = true;
		},
		onSubmit() {
			this.confirm = true;
			// this.dropData();  // status-change
			this.$emit("status-change", true);
			// this.statusDialog = false;
			// this.$emit("status-dialog", false);
		},
		closeMainDialog() {
			this.confirm = false;
			this.statusDialog = false;
		},
		refresh() {
			this.confirm_dialog = false;
			this.getLeaveKanban();
		},
		UpdateLeave(id) {
			this.$emit("updateData", id);
		},
		openDialog(id) {
			this.$emit("open-dialog", id);
		},
		delete_leave(row) {
			this.delTEXT = row.barcode;
			(this.delURL = `leave/${row.id}`), (this.confirm_dialog = true);
			this.getLeaveKanban();
		},
		getProvision(proDate) {
			let a = MomentJS();
			let b = MomentJS(proDate);
			return b.diff(a, "days");
			/* alert(this.probisionTime) */
		},
		getLeaveKanban(query = null, index = null) {
			console.log("this.$route.query ==> ", this.$route.query);
			this.pageLoading = true;
			this.$store
				.dispatch(QUERY, {
					url: `leaves-list-kanban`,
					data: {
						...query,
						status: 4,
						...this.$route.query,
					},
				})
				.then((data) => {
					if (data) {
						if (index) {
							let newData = objectPath.get(data, `${index}.list`);
							if (newData) {
								this.leaveData[index]["list"] = [...this.leaveData[index]["list"], ...newData];
							}
						} else {
							this.leaveData = [...data];
						}

						/*
							this.leaveData.pending = data[0] || null;
							this.leaveData.approved = data[1] || null;
							this.leaveData.rejected = data[2] || null;
						*/
					}

					/*
						const output = [];
						for (let i = 0; i < data.status.length; i++) {
							if (data.status[i].value == 4) {
								continue;
							}
							output.push({
								status: data.status[i],
								list: filter(data.tbody, { status: data.status[i].text }),
							});
						}
						console.log({ output });
						this.leaveData = output;
					*/
				})
				.catch((error) => {
					console.log({ error });
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
		dropData() {
			// if (this.confirm == true) {
			this.update_loading = true;
			this.$store
				.dispatch(PATCH, {
					url: `leave/${this.leaveId}/${this.statusData}`,
					data: {
						reject_remark: this.statusData == 3 ? this.reject_remark : null,
					},
				})
				.then(() => {
					this.getLeaveKanban();
					this.$emit("refressData", true);
					this.$emit("refressStatus", true);
				})
				.catch((error) => {
					this.logError(error);
				})
				.finally(() => {
					this.update_loading = false;
				});
			// }
			// if (this.confirm == false) {
			// 	this.leaveData[this.dropObjectData.index].list = this.applyDrag(
			// 		this.dropObjectData.collection,
			// 		this.dropObjectData.dropResult
			// 	);
			// 	this.getLeaveKanban();
			// 	return false;
			// }
		},
		onDrop(index, collection, dropResult, dropStatus) {
			if (!this.getPermission("leave:approve")) {
				return false;
			}
			const { addedIndex, removedIndex } = dropResult;

			/* To prevent Dialog when drop in same column */
			if ((addedIndex != null && removedIndex != null) || (addedIndex == 0 && removedIndex == 0)) {
				return false;
			}

			if (this.dragstatus != "Rejected") {
				if (this.is_staff) {
					return false;
				}

				if (addedIndex != null) {
					// this.statusdata = dropStatus;
					/* Status Approve or Reject */
					this.$emit("status-data", +dropStatus);
				}
				// if (this.dragstatus != "Pending") {
				// console.log(this.applyDrag(collection, dropResult).filter(Boolean));
				// this.leaveData[index].list = this.applyDrag(collection, dropResult);
				// this.getLeaveKanban();
				// return false;
				// }
				if (addedIndex != null) {
					// this.statusDialog = true;
					/* Dialog Open Close */
					this.$emit("status-dialog", true);
					this.dropObjectData = {
						collection,
						dropResult,
						index,
					};
				}
				/* this.leaveData[index].list = this.applyDrag(collection, dropResult); */
			}
		},
		getChildPayload(row, index, event) {
			// emit to the parent
			this.$emit("leave-details", { barcode: row[event].barcode, leaveId: row[event].id });
			// this.leaveId = row[event].id;
			// this.leaveBarcode = row[event].barcode;
			this.dragstatus = row[event].status;
			return this.leaveData[index];
		},

		applyDrag(output, dragResult) {
			const { removedIndex, addedIndex, payload } = dragResult;
			if (removedIndex === null && addedIndex === null) return output;
			const result = cloneDeep(output);
			let itemToAdd = payload.list[addedIndex];
			if (removedIndex !== null) {
				itemToAdd = result.splice(removedIndex, 1)[0];
			}
			if (addedIndex !== null) {
				result.splice(addedIndex, 0, itemToAdd);
			}
			return result.filter(Boolean);
		},
	},
	computed: {
		is_staff() {
			const user = getCurrentUser();
			const role_name = user.role;
			return role_name == "Staff";
		},
	},
	mounted() {
		this.getLeaveKanban();
		LeaveEventBus.$on("refresh", () => {
			this.getLeaveKanban();
		});
	},
	components: {
		Container,
		Draggable,
		DeleteTemplate,
		// Dialog,
		// TextInput,
	},
};
</script>
