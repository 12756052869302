<template>
	<v-sheet class="leave-main-listing" style="height: calc(100vh - 88px)">
		<v-layout class="flex-wrap">
			<v-flex class="py-0">
				<div style="min-width: 150px; max-width: 250px">
					<v-select
						v-model="status"
						class="listing-select"
						hide-details
						:disabled="pageLoading"
						:items="statusLists"
						item-text="text"
						item-value="value"
						label="All Leave"
						solo
						v-on:change="statusFilterRows($event)"
					>
						<template v-slot:item="{ item }">
							<v-list-item-action>
								<v-avatar size="26" :color="item.status_color" :text-color="item.textcolor">
									<span class="white--text">{{ item.status_count }}</span>
								</v-avatar>
							</v-list-item-action>
							<v-list-item-content>
								<v-list-item-title v-text="item.text"></v-list-item-title>
								<v-list-item-subtitle v-text="item.description"></v-list-item-subtitle>
							</v-list-item-content>
						</template>
					</v-select>
				</div>
			</v-flex>
			<v-spacer></v-spacer>
			<v-flex class="py-0 my-auto d-flex text-right listing-right justify-content-end">
				<template v-if="selected.length > 0">
					<v-menu
						bottom
						left
						origin="center center"
						transition="slide-y-transition"
						rounded="0"
						offset-y
						max-height="400px"
						:close-on-content-click="false"
						content-class="white-background"
					>
						<template v-slot:activator="{ on, attrs }">
							<v-btn
								depressed
								color="blue darken-4"
								class="text-white"
								tile
								:disabled="pageLoading"
								v-bind="attrs"
								v-on="on"
							>
								<v-icon left>mdi-chevron-down</v-icon>
								Bulk Action
							</v-btn>
						</template>
						<v-list class="py-0">
							<template v-for="(item, i) in listingBulkAction">
								<v-list-item
									link
									v-if="item.value != 'mark_as_pending'"
									:key="i + '_' + 'bulk_action'"
									@click="bulkAction(item.value)"
								>
									<v-list-item-title>
										<v-icon v-if="item.icon" left :color="item.color">{{ item.icon }}</v-icon>
										{{ item.title }}
									</v-list-item-title>
								</v-list-item>
								<v-divider v-if="listingBulkAction[i + 1]" :key="`d` + i" class="m-0 p-0"></v-divider>
							</template>
						</v-list>
					</v-menu>
					<v-btn
						depressed
						:disabled="pageLoading"
						tile
						v-on:click="clearSelection"
						color="red lighten-1"
						class="text-white"
					>
						<v-icon left> mdi-close </v-icon>
						Clear Selections
					</v-btn>
				</template>
				<template v-else>
					<v-btn
						v-if="this.getPermission('leave:create')"
						depressed
						tile
						:disabled="pageLoading"
						color="red darken-4"
						class="text-white"
						v-on:click="create_leave_data()"
					>
						<v-icon left> mdi-plus </v-icon>
						Create
					</v-btn>
					<v-btn
						depressed
						:disabled="pageLoading"
						color="blue darken-4"
						class="text-white"
						tile
						min-height="26px"
						x-small
						v-on:click="changeViewType"
					>
						<v-icon v-if="viewType">mdi-view-list</v-icon>
						<v-icon v-else>mdi-trello</v-icon>
					</v-btn>

					<v-btn
						depressed
						:disabled="pageLoading"
						color="blue darken-4"
						class="text-white"
						tile
						v-on:click="routeSetting()"
						min-height="26px"
						x-small
					>
						<v-icon>mdi-cog</v-icon>
					</v-btn>
					<!-- <v-btn
						depressed
						:disabled="pageLoading"
						color="blue darken-4"
						class="text-white"
						tile
						v-on:click="drawerFilter = true"
					>
						<v-icon>mdi-filter</v-icon>
					</v-btn> -->

					<!-- <v-menu
						bottom
						left
						origin="center center"
						transition="slide-y-transition"
						rounded="0"
						offset-y
						max-height="400px"
						:close-on-content-click="false"
						content-class="white-background"
					>
						<template v-slot:activator="{ on, attrs }">
							<v-btn
								depressed
								color="blue darken-4"
								class="text-white"
								tile
								:disabled="pageLoading"
								v-bind="attrs"
								v-on="on"
							>
								<v-icon>mdi-menu</v-icon>
							</v-btn>
						</template>
						<v-list class="py-0">
							<template v-for="(item, i) in listingAction">
								<v-list-item link :key="i" v-on:click="doAction(item.value)">
									<v-list-item-title v-on:click="referess"
										><v-icon v-if="item.icon" left :color="item.color">{{ item.icon }}</v-icon
										>{{ item.title }}</v-list-item-title
									>
								</v-list-item>
								<v-divider v-if="listingAction[i + 1]" :key="`d` + i" class="m-0 p-0"></v-divider>
							</template>
						</v-list>
					</v-menu> -->

					<v-menu
						bottom
						left
						origin="center center"
						transition="slide-y-transition"
						rounded="0"
						offset-y
						max-height="400px"
						:close-on-content-click="false"
						content-class="white-background"
						v-model="theadAction"
					>
						<template v-slot:activator="{ on, attrs }">
							<v-btn
								depressed
								color="blue darken-4"
								class="text-white"
								tile
								:disabled="pageLoading"
								v-bind="attrs"
								v-on="on"
								min-height="26px"
								x-small
							>
								<v-icon>mdi-table-edit</v-icon>
							</v-btn>
						</template>
						<Draggable
							tag="ul"
							v-model="draggableThead"
							class="draggable-group"
							handle=".draggable-drag-icon"
							v-on:change="updateTable('member')"
							v-if="theadAction"
						>
							<template v-for="cols in draggableThead">
								<li class="draggable-group-item" :key="cols.key" v-if="!cols.checkbox">
									<v-checkbox
										dense
										v-model="draggableTheadShow"
										v-bind:value="cols.key"
										:label="cols.name"
										:disabled="cols.fixed || pageLoading"
										color="blue"
										hide-details
										class="mt-0 mb-0"
										v-on:change="updateTableVisiblity('leave')"
									></v-checkbox>
									<v-icon
										v-if="!pageLoading && !cols.fixed"
										class="draggable-action draggable-drag-icon"
										right
										color="blue"
										>mdi-drag</v-icon
									>
									<v-icon v-else right class="draggable-action" color="grey">mdi-drag</v-icon>
								</li>
							</template>
						</Draggable>
					</v-menu>
					<!-- <v-btn
						depressed
						color="blue darken-4"
						class="text-white"
						tile
						v-on:click="summary_show = !summary_show"
						:disabled="pageLoading"
					>
						<v-icon>mdi-chart-box-outline</v-icon>
					</v-btn> -->
				</template>
			</v-flex>
		</v-layout>

		<v-layout>
			<v-flex class="d-flex ml-3">
				<div class="fw-600 my-auto">Filter By</div>
				<div class="mx-2">
					<!-- filterTagSelect  -->
					<v-autocomplete
						v-if="AllowAnyOforGetRoleNType(['master', 'admin', 'manager', 'super_admin'])"
						v-model="customFilter.staff"
						:menu-props="{ bottom: true, offsetY: true }"
						:items="staffList"
						outlined
						clearable
						v-on:click:clear="(type_filter = null), getListing()"
						item-value="id"
						item-text="display_name"
						hide-details
						style="min-width: 280px !important; max-width: 280px !important; max-height: 29px !important"
						placeholder="Staff Name"
						v-on:change="topBarFilter"
					>
						<template v-slot:selection="data">
							<v-chip small v-bind="data.attrs" :input-value="data.selected">
								<v-avatar left>
									<img v-if="data.item.profile_img" :src="data.item.profile_img" />
									<img v-else src="https://ams-dev.businessthrust.com//media/misc/no_photo_found.png" />
								</v-avatar>
								{{ data.item.display_name }}
							</v-chip>
						</template>
						<template v-slot:item="data">
							<template>
								<v-list-item-avatar>
									<img v-if="data.item.profile_img" :src="data.item.profile_img" />
									<img v-else src="https://ams-dev.businessthrust.com//media/misc/no_photo_found.png" />
								</v-list-item-avatar>
								<v-list-item-content>
									<v-list-item-title v-html="data.item.display_name"></v-list-item-title>
								</v-list-item-content>
							</template>
						</template>
					</v-autocomplete>

					<!-- :placeholder="filter.name"
						v-model="serachQuery[filter.key]" -->
					<!-- <v-autocomplete
								v-model="filter_label"
								:items="labelItems"
								:menu-props="{ bottom: true, offsetY: true }"
								prepend-inner-icon="mdi-circle"
								:color="filter_label == 'High' ? 'red' : filter_label == 'Medium' ? 'orange' : 'blue'"
								:class="
									filter_label == 'High'
										? 'red-icon'
										: filter_label == 'Medium'
										? 'orange-icon'
										: 'blue-icon'
								"
								outlined
								hide-details
								class="filterSelect"
								content-class="filterSelectContent"
							>
								<template v-slot:item="data">
									<template>
										<v-list-item-icon class="mr-0 my-1">
											<v-icon
												class="mdi-18px"
												:color="data.item == 'High' ? 'red' : data.item == 'Medium' ? 'orange' : 'blue'"
												>mdi-circle</v-icon
											>
										</v-list-item-icon>
										<v-list-item-content>
											<v-list-item-title v-html="data.item"></v-list-item-title>
										</v-list-item-content>
									</template>
								</template>
							</v-autocomplete> -->
				</div>

				<div class="mr-2">
					<v-autocomplete
						depressed
						hide-details
						outlined
						placeholder="Select Leave Type"
						item-text="category"
						item-value="category"
						clearable
						v-model="customFilter.category"
						:items="leaveTypeList"
						@click:clear="topBarFilter()"
						@change="topBarFilter()"
					/>
					<!-- v-model.trim="serachQuery[filter.key]" -->
				</div>

				<div class="mr-2">
					<DateRangePicker
						hideTopMargin
						hide-details
						:id="filter.key"
						placeholder="Date"
						v-model="customFilter.date"
						:key="clearDateRange"
						@click:clear="
							() => {
								customFilter.date = [];
								topBarFilter();
							}
						"
						@change="
							() => {
								if (
									(customFilter.date && Array.isArray(customFilter.date) && customFilter.date.length > 1) ||
									!customFilter.date
								) {
									topBarFilter();
								}
							}
						"
						clearable
					></DateRangePicker>
					<!-- :minDate="customFilter.date[0]" -->
				</div>

				<!-- <div class="mr-3">
					<DateRangePicker
						hideTopMargin
						hide-details
						:id="filter.key"
						placeholder="End Date"
						v-model="customFilter.end_date"
						v-on:change="topBarFilter()"
						clearable
					></DateRangePicker>
				</div> -->

				<div>
					<v-btn depressed color="red darken-4" tile @click="clearFliter" min-height="30px" x-small>
						<v-icon color="#fff">mdi-close</v-icon>
					</v-btn>
				</div>
				<!-- <div class="filterTagSelect ml-3">
							<v-autocomplete
								v-model="filter_user"
								:items="userItems"
								:menu-props="{ bottom: true, offsetY: true }"
								prepend-inner-icon="mdi-filter"
								outlined
								hide-details
								class="filterSelect"
								content-class="filterSelectContent"
							>
							</v-autocomplete>
						</div> -->
			</v-flex>
		</v-layout>

		<v-layout
			class="page-summary"
			:class="summary_show ? 'show mt-2' : ''"
			style="display: grid; grid-template-columns: repeat(4, 1fr)"
		>
			<template v-for="(statusData, key) in listingStatus">
				<PageSummeryBox
					:key="key"
					:color="statusData.status_color"
					:text="statusData.text"
					:count="statusData.status_count"
					@click="status != statusData.value && statusFilterRows(statusData.value)"
				/>
			</template>
		</v-layout>

		<SearchCriteria
			v-if="search_Enabled()"
			:search-fields="listingFilter"
			search-string="leave #"
			v-on:advance-search="advanceSearch = true"
			v-on:close-search="resetSearch"
		></SearchCriteria>

		<ListingSearchCriteria
			v-if="filterDataAdvance"
			pageModule="Leave"
			:basicSearchFields="['name', 'barcode']"
			:allowedSearchFields="dataFilter"
			v-on:close-search="statusFilterRows('all')"
		></ListingSearchCriteria>

		<!-- (viewType) :- True ? Kanban : Table -->
		<template v-if="viewType">
			<LeaveKanban
				@refressData="getListing()"
				@updateData="updateLeave($event)"
				@refressStatus="getLeaveStatus()"
				@open-dialog="openLeaveDetail($event)"
				@status-dialog="statusDialog = $event"
				@status-data="statusdata = $event"
				@leave-details="leaveDetails"
				@status-change="dropData"
				:status-dailog="statusDialog"
				:status-data="statusdata"
			></LeaveKanban>
		</template>
		<template v-else>
			<Table
				:page-loading="pageLoading"
				type="leave"
				delete-endpoint="leave/"
				is-drawer-detail
				is-dialog-detail
				v-on:open-detail-dialog="openLeaveDetail($event)"
				v-on:open-drawer="open_drawer($event)"
				v-on:reload:content="filterRows"
				:status-filter-data="statusFilterData"
			></Table>
		</template>
		<ExportDialog
			v-if="exportCurrentDialog"
			endpoint="member/export?type=member&current=1"
			title="Members"
			current
			:export-dialog.sync="exportCurrentDialog"
			v-on:close="exportCurrentDialog = false"
		></ExportDialog>
		<ExportDialog
			v-if="exportDialog"
			endpoint="member/export?type=member"
			title="Members"
			:export-dialog.sync="exportDialog"
			@close="exportDialog = false"
		></ExportDialog>
		<ImportDialog
			v-if="importDialog"
			endpoint="member/import?type=member"
			title="Members"
			:import-dialog.sync="importDialog"
			@close="importDialog = false"
		></ImportDialog>
		<FilterDialog
			v-if="advanceSearch"
			title="Members"
			:filter-dialog.sync="advanceSearch"
			:btx-filter.sync="listingFilter"
			:btx-query.sync="btxQuery"
			@do-search="doAdvanceSearch"
			@close="advanceSearch = false"
		>
		</FilterDialog>
		<AdvanceFilter
			v-if="drawerFilter"
			v-on:addvanceFilterData="dataFilter"
			:advanceSearch="drawerFilter"
			v-on:close="drawerFilter = false"
			search-endpoint="leave"
			search-title="Leave"
			:staff-list="staffList"
			:leave-type-list="leaveTypeList"
		></AdvanceFilter>
		<CreateLeave
			v-if="create_leave"
			:drawer="create_leave"
			form-title="Create"
			v-on:close="create_leave = false"
			v-on:refreshCount="(create_leave = false), getLeaveStatus()"
			:leaveUpdateData="editLeave"
		></CreateLeave>
		<!-- v-if="detailDialog" -->

		<LeaveDetailDialog
			:dialog="detailDialog"
			:id="detailId"
			:status-list="leaveStatusList"
			@close="detailDialog = false"
			@status-dialog="statusDialog = $event"
			@status-data="statusdata = $event"
			@leave-details="leaveDetails"
		/>
		<!-- :status-dailog="statusDialog"
			:status-data="statusdata" -->

		<Dialog
			:dialog="statusDialog"
			:dialog-width="statusdata == 2 ? 440 : 600"
			@close="statusDialog = false"
			v-if="statusDialog && statusdata != 1"
		>
			<template v-slot:title> Confirm </template>
			<template v-slot:body>
				<v-form v-model="formValid">
					<v-row class="delete-dialog">
						<v-col md="12" class="py-0 my-auto py-2">
							<p class="btx-p m-0" v-if="statusdata == 2">
								<span class="font-level-1-bold">
									<span style="font-size: 19px !important"> Are you sure </span>
									<br /><br />
									You want to Approve this Leave
									<v-chip outlined label color="green darken-1"
										><span class="fw-600">{{ leaveBarcode }}</span> </v-chip
									>&nbsp;?
								</span>
							</p>
							<div v-if="statusdata == 3">
								<span class="font-level-1-bold">
									<span style="font-size: 19px !important"> Are you sure </span>
									<br /><br />
									You want to Reject {{ leaveId.length > 1 ? "these leaves" : "this Leave" }}
									<v-chip v-if="leaveId.length == 1" outlined label color="red darken-1">
										<span class="fw-600">{{ leaveBarcode }}</span> </v-chip
									>&nbsp; ?
								</span>

								<div class="mt-3">
									<label class="input-label required mb-1">Reject Remark</label>
									<TextAreaInput
										dense
										hide-details
										:rows="4"
										:word-limit="200"
										placeholder="Reject Remark"
										v-model="reject_remark"
										:rules="[vrules.required(reject_remark, 'Reject Remark')]"
										class="mt-0"
										:class="{
											required: !reject_remark,
										}"
									></TextAreaInput>
								</div>
							</div>
						</v-col>
					</v-row>
				</v-form>
			</template>
			<template v-slot:action>
				<v-btn
					class="white--text"
					depressed
					color="blue darken-4"
					@click="dropData()"
					:disabled="!formValid"
					tile
				>
					<!-- v-on:click="onSubmit()" -->
					Yes
				</v-btn>
				<!-- v-on:click="closeMainDialog()" -->
				<v-btn depressed tile @click="statusDialog = false">Close</v-btn>
			</template>
		</Dialog>

		<!-- <LeaveStatusChangeDialogVue /> -->
	</v-sheet>
</template>
<script>
import ListingMixin from "@/core/mixins/listing.mixin";
import LeaveKanban from "@/view/module/leave/LeaveKanban";
import CreateLeave from "@/view/module/leave/CreateLeave";
import AdvanceFilter from "@/view/pages/leads/AdvanceFilter";
import { GET, QUERY, PATCH } from "@/core/services/store/request.module";
import { LeaveEventBus } from "@/core/lib/leave.form.lib";
import ListingSearchCriteria from "@/view/pages/partials/Listing-Search-Criteria.vue";
import LeaveDetailDialog from "@/view/module/leave/LeaveDetailDialog.vue";
import DateRangePicker from "@/view/components/DateRangePickerV2";
import Dialog from "@/view/components/Dialog";
import TextAreaInput from "@/view/components/TextAreaInput";
import { mapGetters } from "vuex";
import {
	SET_TBODY,
	SET_SHOWING_STRING,
	SET_THEAD,
	SET_FILTER,
	SET_STATUS,
	SET_TOTAL_PAGE,
	SET_CURRENT_PAGE,
	SET_BULK_ACTION,
	SET_ACTION,
	GET_SINGLE_ROW,
} from "@/core/services/store/listing.module";
import objectPath from "object-path";
import PageSummeryBox from "@/view/components/PageSummeryBox";

export default {
	name: "leave-listing",
	title: "Listing Leave",
	mixins: [ListingMixin],
	data() {
		return {
			skipBottomHeight: 75,
			theadAction: false,
			detailDialog: false,
			formValid: true,
			detailId: 0,
			filterDataAdvance: false,
			drawerFilter: false,
			viewType: false,
			filter_tag: "Software",
			labelItems: ["High", "Medium", "Low"],
			filter_label: "Medium",
			userItems: ["Everyone"],
			filter_user: "Everyone",
			addvanceFilterAarry: [],
			pageLoading: false,
			create_leave: false,
			pageTitle: "Leave",
			pageBreadcrumbs: [{ text: "Leave", disabled: true }],
			endpoint: "leave",
			defaultFilter: {},
			statusLists: [],
			leaveStatusList: [],
			statusFilterData: [],
			status: "4",
			editLeave: {},
			summary_show: true,
			staffList: [],
			leaveTypeList: [],
			customFilter: {
				staff: null,
				category: null,
				date: [],
			},
			statusDialog: false,
			statusdata: 0,
			reject_remark: null,
			leaveBarcode: null,
			leaveId: null,
			clearDateRange: +new Date(),
		};
	},
	watch: {
		// $route: {
		// 	handler() {
		// 		const { query } = this.$route;
		// 		console.log(query);
		// 	},
		// },
		// "customFilter.date": {
		// 	deep: true,
		// 	handler(param) {
		// 		console.log({ param });
		// 	},
		// },
	},
	methods: {
		bulkAction(action) {
			this.statusdata = action == "mark_as_approved" ? 2 : action == "mark_as_rejected" ? 3 : 1;
			this.leaveId = [...this.selected];
			if (this.leaveId.length == 1) {
				let id = objectPath.get(this.leaveId, "0");
				if (id) {
					this.$store.dispatch(GET_SINGLE_ROW, id).then((row) => {
						if (row) {
							let barcode = objectPath.get(row, "barcode");
							this.leaveBarcode = barcode;
						}
					});
				}
			}
			if (action == "mark_as_rejected") {
				this.reject_remark = null;
				this.statusDialog = true;
				return false;
			}
			this.dropData();
		},
		leaveDetails(event) {
			this.leaveBarcode = event.barcode;
			this.leaveId = [event.leaveId];
		},
		open_filter() {
			this.addvanceFilterAarry = [];
			this.drawerFilter = true;
		},
		open_drawer(id) {
			this.create_leave = true;
			this.$store
				.dispatch(GET, { url: `single-leave/${id}` })
				.then((data) => {
					this.editLeave = data;
				})
				.catch((error) => {
					console.log({ error });
				});
		},
		dataFilter(value) {
			this.addvanceFilterAarry = value;
			this.filterDataAdvance = true;
		},
		openLeaveDetail(id) {
			this.detailId = id;
			this.detailDialog = true;
		},
		updateLeave(id) {
			this.open_drawer(id);
		},
		create_leave_data() {
			this.editLeave = {};
			this.create_leave = true;
		},
		referess() {
			window.location.reload();
		},
		dropData() {
			this.pageLoading = true;
			let payload = {
				leaves: this.leaveId,
				status: this.statusdata, // 1: Pending, 2: Approved, 3: Rejected
				reject_remark: this.statusdata == 3 ? this.reject_remark : null,
			};
			this.$store
				.dispatch(PATCH, {
					url: `leave-status-update`,
					data: payload,
				})
				.then(() => {
					LeaveEventBus.$emit("refresh");
					this.getLeaveStatus();
					this.statusDialog = false;
					this.detailDialog = false;
					this.reject_remark = null;
				})
				.catch((error) => {
					this.logError(error);
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
		getLeaveStatus() {
			this.pageLoading = true;
			this.$store
				.dispatch(GET, { url: "leave-setting" })
				.then((data) => {
					this.statusLists = data.status;
					this.staffList = data && data.staff;
					this.leaveTypeList =
						data && data.leave_types
							? [
									...data.leave_types,
									{
										category: "UnPaid",
									},
							  ]
							: [];

					let _status = [];
					data.status.forEach((row) => {
						if (row.value != 4) {
							_status.push({ text: row.text, value: row.text, status_color: row.status_color });
						}
					});
					this.leaveStatusList = _status;
				})
				.catch((error) => {
					console.log({ error });
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
		statusFilterRows(value) {
			//console.log('test');
			this.status = value;
			this.pageLoading = true;
			this.allFiltersStatus(value);
			this.$store
				.dispatch(QUERY, {
					url: `leave`,
					data: {
						status: value,
						staff: this.customFilter?.staff,
						start_date: this.customFilter?.date[0],
						end_date: this.customFilter?.date[1],
						category: this.customFilter?.category,
					},
				})
				.then((data) => {
					this.$store.commit(SET_TBODY, data.tbody);
					this.$store.commit(SET_CURRENT_PAGE, data.current_page);
					this.$store.commit(SET_TOTAL_PAGE, data.total_page);
					this.$store.commit(SET_SHOWING_STRING, data.showing_string);
					this.$store.commit(SET_STATUS, data.status);
					this.addvanceFilterAarry = [];
					this.filterDataAdvance = false;
				})
				.catch((error) => {
					console.log({
						error,
					});
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
		allFiltersStatus(value) {
			this.$router.push({
				name: "leave",
				query: {
					...this.$route.query,
					status: value,
					t: new Date().getTime(),
				},
			});
		},
		routeSetting() {
			this.$router.push({
				name: "setting",
				query: {
					tab: "leave",
				},
			});
		},
		clearFliter() {
			this.customFilter.staff = null;
			this.customFilter.date = [];
			this.customFilter.category = null;
			this.topBarFilter();
			this.clearDateRange = +new Date();
		},
		topBarFilter() {
			let filter = {
				status: this.status || "all",
				staff: this.customFilter.staff,
				start_date: (this.customFilter.date && this.customFilter.date[0]) || null,
				end_date: (this.customFilter.date && this.customFilter.date[1]) || null,
				category: this.customFilter.category,
				viewType: this.viewType,
			};

			this.pageLoading = true;
			this.allFiltersSetting(filter);
			this.$store
				.dispatch(QUERY, {
					url: `leave`,
					data: filter,
				})
				.then((data) => {
					this.$store.commit(SET_ACTION, data.action);
					this.$store.commit(SET_BULK_ACTION, data.bulk_action);
					this.$store.commit(SET_CURRENT_PAGE, data.current_page);
					this.$store.commit(SET_TOTAL_PAGE, data.total_page);
					this.$store.commit(SET_SHOWING_STRING, data.showing_string);
					this.$store.commit(SET_STATUS, data.status);
					this.$store.commit(SET_FILTER, data.filter);
					this.$store.commit(SET_TBODY, data.tbody);
					this.$store.commit(SET_THEAD, data.thead);
				})
				.catch((error) => {
					console.log({
						error,
					});
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
		allFiltersSetting(filter) {
			this.$router.push({
				name: "leave",
				query: {
					...filter,
					// staff: filter.staff,
					// date: [filter.start_date, filter.end_date],
					// category: filter.category,
				},
			});
		},
		changeViewType() {
			this.viewType = !this.viewType;

			let query = { ...this.$route.query, viewType: this.viewType };

			this.$router.push({
				name: "leave",
				query: query,
			});
		},
		getSearchQuery() {
			const parmQuery = this.$route.query;
			for (let row in parmQuery) {
				if (row == "start_date") {
					this.customFilter.date[0] = parmQuery[row];
				} else if (row == "end_date") {
					this.customFilter.date[1] = parmQuery[row];
				} else if (row == "viewType") {
					this.viewType = parmQuery[row] == "true" ? true : false;
				} else {
					this.customFilter[row] = +parmQuery[row] ? +parmQuery[row] : parmQuery[row];
				}
			}
		},
	},
	components: {
		LeaveKanban,
		CreateLeave,
		AdvanceFilter,
		ListingSearchCriteria,
		LeaveDetailDialog,
		DateRangePicker,
		Dialog,
		TextAreaInput,
		PageSummeryBox,
	},
	computed: {
		...mapGetters(["selected", "listingStatus"]),
	},
	beforeMount() {
		const parmQuery = this.$route.query;

		this.viewType = parmQuery.viewType == "true" ? true : false;
		this.customFilter.date[0] = parmQuery.start_date || null;
		this.customFilter.date[1] = parmQuery.end_date || null;
		this.customFilter.staff = +parmQuery.staff || null;
		this.customFilter.category = parmQuery.category || null;
	},
	mounted() {
		// this.getSearchQuery();
		this.getLeaveStatus();
		let filterQuery = this.$route.query;

		LeaveEventBus.$on("refresh", (data) => {
			if (data?.page) {
				filterQuery.page = 1;

				this.$router.replace({
					query: {
						...filterQuery,
					},
				});
			}
			this.getListing(data?.page);
		});
	},
};
</script>
